:root {
  --bg: #EDF2F0;
  --neu-1: #ecf0f3;
  --neu-2: #d1d9e6;
  --white: #f9f9f9;
  --gray: #a0a5a8;
  --black: #181818;
  --purple: #4B70E2;
  --transition: 1.25s;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
} 

.parent {
  width: 100%;
  height: auto;
 padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  background-color: var(--neu-1);
  color: var(--gray);
} 

.mainSingIN {
  position: relative;
  width: 1000px;
  min-width: 1000px;
  min-height: 600px;
  height: 600px;
  padding: 25px;
  background-color: var(--neu-1);
  box-shadow: 10px 10px 10px var(--neu-2), -10px -10px 10px var(--white);
  border-radius: 12px;
  overflow: hidden;

  /* width: 100%;
  height: 100vh; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  background-color: var(--neu-1);
  color: var(--gray); */
}

@media (max-width: 1200px) {
  .mainSingIN {
    transform: scale(0.7);
  }
}
@media (max-width: 1000px) {
  .mainSingIN {
    transform: scale(0.6);
  }
}
@media (max-width: 800px) {
  .mainSingIN {
      transform: scale(0.5);
  }
}  
@media (max-width: 600px) {
  .mainSingIN {
      transform: scale(0.4);
  }
} 

.containerone {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 600px;
  height: 100%;
  padding: 25px;
  background-color: var(--neu-1);
  transition: var(--transition);
}
.containeroneForDevice {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  padding: 25px;
  background-color: var(--neu-1);
  transition: var(--transition);
}
.containerstwoFroDevice {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  padding: 25px;
  background-color: var(--neu-1);
  transition: var(--transition);
}

.containers {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 600px;
  height: 100%;
  padding: 25px;
  background-color: var(--neu-1);
  transition: var(--transition);
}

.formSignIn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.formSignIn__icon {
  object-fit: contain;
  width: 30px;
  margin: 0 5px;
  opacity: 0.5;
  transition: 0.15s;
}
.formSignIn__icon:hover {
  opacity: 1;
  transition: 0.15s;
  cursor: pointer;
}
.formSignIn__input {
  width: 350px !important;
  height: 40px;
  margin: 4px 0;
  padding-left: 25px;
  font-size: 13px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--neu-1) !important;
  transition: 0.25s ease;
  border-radius: 8px;
  box-shadow: inset 2px 2px 4px var(--neu-2), inset -2px -2px 4px var(--white);
}
.formSignIn__input:focus {
  box-shadow: inset 4px 4px 4px var(--neu-2), inset -4px -4px 4px var(--white);
}
  
  .formSignIn__span {
  margin-top: 30px;
  margin-bottom: 12px;
  }
  .formSignIn__link {
      color: #181818;
      font-size: 15px;
      margin-top: 25px;
      border-bottom: 1px solid #a0a5a8;
      line-height: 2;
  }


  .title {
      font-size: 34px;
      font-weight: 700;
      line-height: 3;
      color: #181818;
  }
  .description {
      font-size: 14px;
      letter-spacing: .25px;
      text-align: center;
      line-height: 1.6;
  }
  .buttonSignIn {
      width: 180px;
      height: 50px;
      border-radius: 25px;
      margin-top: 50px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 1.15px;
      background-color: #4B70E2;
      color: #f9f9f9;
      box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
      border: none;
      outline: none;
  }
  .a-containerone {
      z-index: 100;
      left: calc(100% - 600px);
  }
  .b-containers {
      left: calc(100% - 800px);
      z-index: 0;
  }
  .switch {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 400px;
      padding: 50px;
      z-index: 200;
      transition: 1.25s;
      background-color: #ecf0f3;
      overflow: hidden;
      box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
  }
  .switch__circle {
      position: absolute;
      width: 500px;
      height: 500px;
      border-radius: 50%;
      background-color: #ecf0f3;
      box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
      bottom: -60%;
      left: -60%;
      transition: 1.25s;
  }
  .switch__circle--t {
      top: -30%;
      left: 60%;
      width: 300px;
      height: 300px;
  }
  .switch__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      width: 400px;
      padding: 50px 55px;
      transition: 1.25s;
  }
  .switch__button {
      cursor: pointer;
  }
  .switch__button:hover {
      box-shadow: 6px 6px 10px #d1d9e6, -6px -6px 10px #f9f9f9;
      transform: scale(.985);
      transition: .25s;
  }
  .switch__button:active,
  .switch__button:focus {
      box-shadow: 2px 2px 6px #d1d9e6, -2px -2px 6px #f9f9f9;
      transform: scale(.97);
      transition: .25s;
  }
  .is-txr {
      left: calc(100% - 400px);
      transition: 1.25s;
      transform-origin: left;
  }
  .is-txl {
      left: 0;
      transition: 1.25s;
      transform-origin: right;
  }
  .is-z200 {
      z-index: 200;
      transition: 1.25s;
  }
  .is-hidden {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      transition: 1.25s;
  }
  .is-gx {
      animation: is-gx 1.25s;
  }
  @keyframes is-gx {
      0%, 10%, 100%{ width: 400px; }
      30%, 50%{ width: 500px;}
  }
  

  .firstLogin, .secondLogin {
    /* background-color: rebeccapurple !important; */
    width: 200px !important;
  }

  .firstLoginForDevice, .secondLoginForDevice {
    width: 180px !important;
  }