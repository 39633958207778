.research-papers {
    padding: 2rem 0;
  }
.researchEachPaper {
  background-color: #ECF0F3;
  color: black !important
}
.researchEachPaperTitle {
  /* color: white !important */
}
  
  .card {
    border: none;
  }
  
  .card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .card-subtitle {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .card-text {
    font-size: 1.1rem;
    line-height: 1.5;
  }
  
  @media screen and (max-width: 767px) {
    .card {
      height: auto;
    }
  }
  