.card {
    margin-bottom: 30px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-subtitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .card-text {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0062cc;
    border-color: #005cbf;
  }
  
  .btn-primary:active {
    background-color: #0062cc;
    border-color: #005cbf;
  }
  
  .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  /* .researchDetail .row > :first-child {
    position: fixed;
    top: 0;
  } */

  .sticky-col {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  