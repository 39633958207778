/* JournalDetail component styles */

/* Journal header */
.journal-header {
    position: relative;
    height: 400px;
    background-size: cover;
    background-position: center;
  }
  
  .journal-header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .journal-header h1 {
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    margin-top: 80px;
    margin-bottom: 20px;
  }
  
  .journal-header p {
    font-size: 24px;
    color: #fff;
    max-width: 800px;
    margin-bottom: 80px;
  }
  
  /* Journal details */
  .journal-detail .card {
    border-radius: 0;
  }
  
  .journal-detail .card-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .journal-detail .card-text {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .journal-detail a {
    color: #007bff;
    text-decoration: none;
  }
  
  .journal-detail a:hover {
    text-decoration: underline;
  }
  
  .journal-detail .latest-issue {
    text-align: center;
    margin-top: 40px;
  }
  
  .journal-detail .latest-issue .card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .journal-detail .latest-issue .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .journal-detail .latest-issue .card-text {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .journal-detail .latest-issue .card-img-top {
    border-radius: 8px 8px 0 0;
  }
  
  .journal-detail .latest-issue .btn {
    margin-top: 10px;
  }
  

  /* .jounaldetailfixedDiv {
    position: sticky;
     top: 80px
  } */

  @media  screen and (min-width:400px)  {
    .jounaldetailfixedDiv {
      position: sticky;
       top: 80px
    }
}