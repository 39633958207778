.navbar {
    animation: bounce-top 1s both;
    position: fixed !important;
    top: 0 ;
    width: 100vw;
     /* background:rgba(255,255,255,0.1); */
     background: white;
    backdrop-filter: blur(5px);
    z-index: 1000000;
    border-bottom: 1px solid #206dfe;
    box-shadow: #858687 0px 5px 15px;
    animation:bounce-top 1s both;


}
.navbarPhoneBtnHover:hover {
    background: #858687 !important;
    color: white !important;
    transition: .5s;
}


.dropBtn {
    position: relative;
}
.dropDownlist {
    position: absolute;
    bottom: -90px;
    /* border: 1px solid red; */
    padding-top: 1rem;
    background: white;
    display: none;
}
.dropBtn:hover .dropDownlist {
    display: block;
}
.dropDownlist>p{
    margin: 0;
    min-width: 220px;
    margin: .3rem 0
}
/* .activeNav {

} */
.night-mode {
    background: #212632 !important;
    color: white !important;
    
}


@keyframes bounce-top{0%{transform:translateY(-45px);animation-timing-function:ease-in;opacity:1}24%{opacity:1}40%{transform:translateY(-24px);animation-timing-function:ease-in}65%{transform:translateY(-12px);animation-timing-function:ease-in}82%{transform:translateY(-6px);animation-timing-function:ease-in}93%{transform:translateY(-4px);animation-timing-function:ease-in}25%,55%,75%,87%{transform:translateY(0);animation-timing-function:ease-out}100%{transform:translateY(0);animation-timing-function:ease-out;opacity:1}}


.activetext {
    color: #206dfe !important;
}

@media  screen and (max-width:990px)  {
.menulistparent {
    display: none;
    /* border: 1px solid red; */
    animation:bounce-top 1s both;
    margin: 2rem 0;
    margin-bottom: 1rem;
    /* padding-left: 1rem; */
    width: 100%;
    /* text-align: center; */
    position: absolute;
    width: 100%;
    top: 40%;
     background:#D6D6D6;
    backdrop-filter: blur(50px);


}
.menulistparent.activeNavbar {
    display: block;
}
.menulistparent>ul {
    padding: 1rem;
}
.menulistparent>ul>li {
    list-style-type: none;
    border: 1px solid #206dfe;
    padding: .8rem 1rem;
    width: 90%;
    margin: auto;
    margin-bottom: .5rem;
}

}

.logoText {
    display: none;
}

@media  screen and (min-width:450px)  {
    .dropdown-user-device {
        display: none;
    }
    .logoText {
        display: inline-block;
    }
}