.search-bar {
    width: 800px;
  }
  
  @media (max-width: 768px) { /* Change this breakpoint as per your requirement */
    .search-bar {
      width: 350px !important;
    }
}

.year-picker  {
  width: 140px !important;
  height: 37px !important;
   padding: .3rem .2rem !important;
    border-radius: 5px !important;
     border: 1px solid black !important;
}
.year-picker .footer {
  width: 100% !important;
  color: white !important;
  background: white !important;
}
.year-picker .year  {
  text-decoration: none;
  color: black;
}
.engBooksBodymain {
  min-height: 300px !important;
}

.subCateParent .subCate:hover {
  /* background-color: #0D6EFD !important; */
  box-shadow: 0 0 11px rgba(33,33,33,.2) !important; 
}