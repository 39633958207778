.journalEachPaper {
    background-color: #ECF0F3;
    color: black !important
  }
.nightHome.journal{
  color: black !important 
}
    
.lightJournal {
  background-color: #F4F4F4 !important;
}
  
.list-unstyled li {
  display: block;
  margin-bottom: 10px;
}
.nightJournalDetail {
  background: #212632 !important;
  
}