@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&display=swap');


body {
  
  margin: 0;
  font-family: 'Abyssinica SIL', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

