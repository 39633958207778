* {
    box-sizing: border-box;
}

@media  screen and (min-width:990px)  {
    .bookfilterfixedDiv {
        position: fixed;
        top: 100 
    }
}

@media  screen and (max-width:990px)  {
    .mmbookslistframe, .engbookslistframe, .journallistframe {
        display: flex;
        flex-direction: column-reverse;
    }
    .filterDataDeviceSize {
        display: none;
    }
}
.year-picker  {
    width: 140px !important;
    height: 37px !important;
     padding: .3rem .2rem !important;
      border-radius: 5px !important;
       border: 1px solid black !important;
}
.year-picker .footer {
    width: 100% !important;
    color: white !important;
    background: white !important;
}
.year-picker .year  {
    text-decoration: none;
    color: black;
}
.mmbooksBody {
    min-height: 300px !important;
}
#download {
    display: none !important;
}