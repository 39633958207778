.card-text span:first-child {
    display: inline-block;
    width: 200px; /* Adjust width as needed */
  }

  .pdfobject-container { position: relative; }
    .pdfobject { pointer-events: none; }
    .pdfobject-container > a { position: absolute; top: 0; right: 0; opacity: 0; }



  @media  screen and (max-width:850px)  {
    .phoneSizePdfViewer {
      display: block;
    }
    .laptopSizePdfViewer {
      display: none;
    }
  }

  @media  screen and (min-width:850px)  {
    .phoneSizePdfViewer {
      display: none;
    }
    .laptopSizePdfViewer {
      display: block;
    }
  }

  .nightBookDetail {
    background: #212632 !important;
  }